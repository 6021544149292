import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Coworking from '../views/Coworking.vue'
import Spazi from '../views/Spazi.vue'
import Innovazioni from '../views/Innovazioni.vue'
import Well_be from '../views/Well_be.vue'
import Contatti from '../views/Contatti.vue'
import PageNotFound from '../views/PageNotFound.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/coworking',
    name: 'Coworking',
    component: Coworking
  },
  {
    path: '/spazi',
    name: 'Spazi',
    component: Spazi
  },
  {
    path: '/innovazioni',
    name: 'Innovazioni',
    component: Innovazioni
  },
  {
    path: '/well_be',
    name: 'Well_be',
    component: Well_be
  },
  {
    path: '/contatti',
    name: 'Contatti',
    component: Contatti
  },
{ path: '/404', component: PageNotFound },  
  { path: '*', redirect: '/404' },  
]

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes,
  mode: 'history',
  // eslint-disable-next-line no-unused-vars
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 }
  },
  
});


export default router
