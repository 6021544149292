<template>
  <div class="about">
    <Header />
    <main>
      <!-- page title area start -->
      <section class="page__title p-relative d-flex align-items-center bk-page">
        <div class="page__title-bg bk-spazi"></div>
        <div class="container">
          <div class="row">
            <div class="col-xl-12">
              <div class="page__title-content mt-100 text-center">
                <h2>Gli Spazi</h2>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- page title area end -->

      <!-- team  -->
      <div class="black-bg">
        <div
          class="team-area pt-120 dot"
          data-background="../assets/img/pattern/dot.png"
        >
          <div class="container">
            <div class="row pb-60">
              <!-- <p v-html="$t('spazi.description')" class="text-white"></p> -->
              <div class="col-12">
                <p class="text-white">
                  Gli spazi proposti da Well-B sono tutti
                  <strong>altamente tecnologici</strong>, attrezzati
                  appositamente per i professionisti del benessere. L’intero
                  coworking è stato ristrutturato secondo le
                  <strong>normative vigenti</strong> e nel rispetto delle
                  <strong>esigenze specifiche del settore</strong>. I
                  professionisti avranno così la sicurezza di appoggiarsi a uno
                  spazio professionale, igienico, attrezzato e al 100%
                  riservato. <br /><br />
                  Data la particolare situazione in corso, Well-B Space è dotato
                  anche di un sistema di
                  <strong>filtraggio dell’aria</strong> atto a ridurre il
                  rischio di contagio da <strong>Covid-19</strong>.
                </p>
              </div>
            
                <div class="col-12 mt-4 text-center">
                  <h1 class="mb-30">{{ $t("spazi.title") }}</h1>
                </div>
                <div class="mb-60">
                  <swiper class="swiper col-10 m-auto" :options="swiperOption">
                    <swiper-slide
                      ><img
                        src="../assets/img/spazi/Spazi_scorrimento1.webp"
                        alt="slide"
                    /></swiper-slide>
                    <swiper-slide
                      ><img
                        src="../assets/img/spazi/Spazi_scorrimento2.webp"
                        alt="slide"
                    /></swiper-slide>
                    <swiper-slide
                      ><img
                        src="../assets/img/spazi/Spazi_scorrimento3.webp"
                        alt="slide"
                    /></swiper-slide>
                    <swiper-slide
                      ><img
                        src="../assets/img/spazi/Spazi_scorrimento4.webp"
                        alt="slide"
                    /></swiper-slide>
                    <swiper-slide
                      ><img
                        src="../assets/img/spazi/Spazi_scorrimento5.webp"
                        alt="slide"
                    /></swiper-slide>
                    <div class="swiper-button-prev" slot="button-prev"></div>
                    <div class="swiper-button-next" slot="button-next"></div>
                  </swiper>
                </div>
            
              <div class="col-12">
                <p class="text-white mt-2">
                  Well-B Space propone <strong>6 unità individuali</strong> e
                  perfettamente isolate tra loro. Di queste, 3 sono arredate con
                  scrivanie, sedie da ufficio, armadietti. Le altre 3 sono
                  invece indicate con <strong>esigenze più specifiche</strong>,
                  dato che propongono anche <strong>lettini</strong>, carrelli
                  di servizio e lampade. Tutte le unità sono dotate di
                  ventilazione controllata, in modo da assicurare ricambio e
                  filtraggio costante dell’aria.",
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="grey-bg">
        <div class="main-services pt2 pt-120 pb-90">
          <div class="container">
            <div class="row mb-60">
              <div class="col-12 text-center">
                <h1 class="mb-30">Gli spazi</h1>
              </div>

              <swiper class="swiper col-10 m-auto" :options="swiperOption">
                <swiper-slide
                  ><img src="../assets/img/spazi/spazi_1.webp" alt="slide"
                /></swiper-slide>
                <swiper-slide
                  ><img src="../assets/img/spazi/spazi_2.webp" alt="slide"
                /></swiper-slide>
                <swiper-slide
                  ><img src="../assets/img/spazi/spazi_3.webp" alt="slide"
                /></swiper-slide>
                <swiper-slide
                  ><img src="../assets/img/spazi/spazi_4.webp" alt="slide"
                /></swiper-slide>
                <swiper-slide
                  ><img src="../assets/img/spazi/spazi_5.webp" alt="slide"
                /></swiper-slide>
                <swiper-slide
                  ><img
                    src="../assets/img/spazi/Spazi_scorrimento5.webp"
                    alt="slide"
                /></swiper-slide>
                <div class="swiper-button-prev" slot="button-prev"></div>
                <div class="swiper-button-next" slot="button-next"></div>
              </swiper>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="sec-wrapper text-start mt-20">
                  <ul>
                    <li style="font-size: 20px" class="mb-20">
                      <strong>{{ $t("spazi.spazio") }} 1</strong> <br />
                      <span v-html="$t('spazi.decrSpazio1')"></span>
                    </li>
                    <li style="font-size: 20px" class="mb-20">
                      <strong>{{ $t("spazi.spazio") }} 2</strong> <br />
                      <span v-html="$t('spazi.decrSpazio1')"></span>
                    </li>
                    <li style="font-size: 20px" class="mb-20">
                      <strong>{{ $t("spazi.spazio") }} 3</strong> <br />
                      <span v-html="$t('spazi.decrSpazio1')"></span>
                    </li>
                    <li style="font-size: 20px" class="mb-20">
                      <strong>{{ $t("spazi.spazio") }} 4</strong> <br />
                      <span v-html="$t('spazi.decrSpazio2')"></span>
                    </li>
                    <li style="font-size: 20px" class="mb-20">
                      <strong>{{ $t("spazi.spazio") }} 5</strong> <br />
                      <span v-html="$t('spazi.decrSpazio2')"></span>
                    </li>
                    <li style="font-size: 20px" class="mb-60">
                      <strong>{{ $t("spazi.spazio") }} 6</strong> <br />
                      <span v-html="$t('spazi.decrSpazio3')"></span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-12">
                <div class="sec-wrapper text-start mt-20">
                  <ul>
                    <li style="font-size: 20px">
                      {{ $t("spazi.other") }}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- team  -->
    </main>
    <Footer />
  </div>
</template>

<script>
// @ is an alias to /src
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";

export default {
  name: "About",
  components: {
    Header,
    Footer,
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      swiperOption: {
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
    };
  },
};
</script>
