<template>
  <div class="about">
    <Header />
    <main>
      <!-- page title area start -->
      <section class="page__title p-relative d-flex align-items-center bk-page">
        <div class="page__title-bg bk-about"></div>
        <div class="container">
          <div class="row">
            <div class="col-xl-12">
              <div class="page__title-content mt-100 text-center">
                <h2>entra in well-b</h2>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- page title area end -->

      <div class="main-services grey-bg pt2 pt-120 pb-90">
        <div class="container">
          <div class="row mb-60">
            <div class="col-12">
              <div class="sec-wrapper text-start">
                <p>
                  Per accedere ai tanti servizi proposti da
                  <strong>Well-B Space</strong> ti basta un cellulare: il
                  processo di <strong>registrazione</strong>, prenotazione e
                  pagamento è tutto digitale e automatizzato.
                </p>
                <p>
                  Il primo passo è registrarsi al sito e sottoscrivere lo
                  <strong>smart contract</strong>, accettando così le condizioni
                  dello spazio di coworking e del sito. In questa fase vengono
                  richiesti tutti i dati fondamentali della persona interessata,
                  affinché il sistema possa creare un’<strong
                    >identità digitale</strong
                  >
                  unica e <strong>sicura</strong>. Una volta fatto, è possibile
                  accedere all’area riservata del sito dedicata alle
                  prenotazioni.
                </p>
                <div class="grey-bg">
                  <div class="main-services pt2  pb-90">
                    <div class="row">
                     
                      <swiper
                        class="swiper col-10 m-auto"
                        :options="swiperOption"
                      >
                     
                        <swiper-slide
                          ><img
                            src="../assets/img/MyWB/mywell_2.webp"
                            alt="slide"
                        /></swiper-slide>
                        <swiper-slide
                          ><img
                            src="../assets/img/MyWB/mywell_3.webp"
                            alt="slide"
                        /></swiper-slide>
                       
                        
                        <div
                          class="swiper-button-prev"
                          slot="button-prev"
                        ></div>
                        <div
                          class="swiper-button-next"
                          slot="button-next"
                        ></div>
                      </swiper>
                    </div>
                  </div>
                </div>
                <p>
                  All’interno dell’area riservata sono presenti
                  <strong
                    >orari e date disponibili, spazi liberi, servizi
                    prenotabili</strong
                  >
                  e <strong>prezzi</strong>. Grazie all’identità digitale creta
                  in fase di registrazione, il professionista può prenotare lo
                  spazio per un tempo che va dalla
                  <strong>mezza giornata</strong> ai <strong>6 mesi</strong>,
                  con o senza servizi accessori.
                </p>
                <p>
                  Per accedere allo spazio prenotato si usa l’applicazione
                  Well-B, mediante la quale si ottiene la
                  <strong>chiave elettronica</strong> necessaria per entrare
                  fisicamente nell’edificio e nell’ufficio. Mediante
                  l’applicazione si possono inoltre effettuare i pagamenti e
                  prenotare tutti i servizi disponibili sul sito.
                </p>
                <div class="grey-bg">
                  <div class="main-services pt2 pb-90">
                    <div class="row">
                     
                      <swiper
                        class="swiper col-10 m-auto"
                        :options="swiperOption"
                      >
                     
                        <swiper-slide
                          ><img
                            src="../assets/img/MyWB/mywell_4.webp"
                            alt="slide"
                        /></swiper-slide>
                        <swiper-slide
                          ><img
                            src="../assets/img/MyWB/mywell_5.webp"
                            alt="slide"
                        /></swiper-slide>
                       
                        
                        <div
                          class="swiper-button-prev"
                          slot="button-prev"
                        ></div>
                        <div
                          class="swiper-button-next"
                          slot="button-next"
                        ></div>
                      </swiper>
                    </div>
                  </div>
                </div>
                <p>Registrati per scoprire e usare i nostri prodotti.</p>
                <div class="text-center mt-5 pt-5">
                  <a
                    style="color: white"
                    class="tp-btn"
                    href="https://app.well-b.space/login"
                    >{{ $t("menu.book") }}</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- brand area end here -->
      <!--  <div class="brand-area bg-grey pt-100 pb-100">
         <div class="container">
             <div class="brand-active swiper-container">
                 <div class="swiper-wrapper align-items-center">
                     <div class="brand-wrapper swiper-slide wow fadeInUp" data-wow-delay=".3s" data-swiper-autoplay="10000">
                         <a href="#"><img src="assets/img/brand/brand-1.png" class="img-fluid" alt="img"></a>
                     </div>
                     <div class="brand-wrapper swiper-slide wow fadeInUp" data-wow-delay=".6s" data-swiper-autoplay="10000">
                         <a href="#"><img src="assets/img/brand/brand-2.png" class="img-fluid" alt="img"></a>
                     </div>
                     <div class="brand-wrapper swiper-slide wow fadeInUp" data-wow-delay=".9s" data-swiper-autoplay="10000">
                         <a href="#"><img src="assets/img/brand/brand-3.png" class="img-fluid" alt="img"></a>
                     </div>
                     <div class="brand-wrapper swiper-slide wow fadeInUp" data-wow-delay="1.2s" data-swiper-autoplay="10000">
                         <a href="#"><img src="assets/img/brand/brand-4.png" class="img-fluid" alt="img"></a>
                     </div>
                     <div class="brand-wrapper swiper-slide wow fadeInUp" data-wow-delay="1.5s" data-swiper-autoplay="10000">
                         <a href="#"><img src="assets/img/brand/brand-5.png" class="img-fluid" alt="img"></a>
                     </div>
                     <div class="brand-wrapper swiper-slide wow fadeInUp" data-wow-delay="1.8s" data-swiper-autoplay="10000">
                         <a href="#"><img src="assets/img/brand/brand-1.png" class="img-fluid" alt="img"></a>
                     </div>
                 </div>
             </div>
         </div>
     </div> -->
      <!-- brand area start here -->
    </main>
    <Footer />
  </div>
</template>

<script>
// @ is an alias to /src
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";

export default {
  name: "Well_be",
  components: {
    Header,
    Footer,
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      swiperOption: {
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
    };
  },
};
</script>
