<template>
  <div class="coworking">
    <Header />
    <main>
      <!-- page title area start -->
      <section
        class="page__title p-relative d-flex align-items-center bk-page"
      >
        <div
          class="page__title-bg bk-spazi"
        ></div>
        <div class="container">
          <div class="row">
            <div class="col-xl-12">
              <div class="page__title-content mt-100 text-center">
                <h2>Più di un Coworking</h2>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- page title area end -->

      <!-- about start -->
      <div class="about-area pt-120 pb-90">
        <div class="container">
          <div class="row">
            <div class="col-xl-5 col-lg-5">
              <div class="ab-wrapper pos-rel mt-70">
                <div class="ab-since"></div>
                <div class="row gx-2 mb-30">
                  <div class="col-6">
                    <div class="ab-img img-filter">
                      <img src="../assets/img/about/Cowo Griglia 1.webp" alt="" />
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="ab-img img-filter">
                      <img src="../assets/img/about/cowo_2.webp" alt="" />
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="ab-img img-filter">
                      <img src="../assets/img/about/cowo_3.webp" alt="" />
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="ab-img img-filter">
                      <img src="../assets/img/about/cowo_4.webp" alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xl-7 col-lg-7">
              <div class="ab-box pl-30 mb-30">
                <div class="sec-wrapper">
                  <h2 class="section-title text-sx">{{$t('coworking.title')}}</h2>
                </div>
                <div class="ab-item-box mt-20 mb-20">
                  <p v-html="$t('coworking.paragraph')">
                   
                  </p>
                  
                </div>
                <div class="ab-btn text-sx">
                  <router-link style="color: white" class="tp-btn" to="/about">{{$t('home.button')}}</router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- about end -->
    </main>
    <Footer />
  </div>
</template>

<script>
// @ is an alias to /src
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: "Coworking",
  components: {
    Header,
    Footer,
  },
};
</script>
