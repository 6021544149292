<template>
  <div :class="{ loader: true, fadeout: !isLoading }">
    <img class="image" src="../assets/img/preloader/logo w-b.svg" alt="" />
  </div>
</template>

<script>
export default {
  name: "Loader",
  props: ["isLoading"],
};
</script>
<style scoped>
.fadeout {
  animation: fadeout 1s forwards;
}
@keyframes fadeout {
  to {
    opacity: 0;
    visibility: hidden;
  }
}
.loader {
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
}
.image {
  width: 30%;
  margin: -60px 0 0 -60px;
  opacity: 0;
  -webkit-animation: spin 1s linear ;
  -moz-animation: spin 1s linear ;
  animation: spin 1s linear ;
}
@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
    opacity: 0;
  }
}
@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    opacity: 0.3;
  }
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
    opacity: 1;
  }
}
</style>
