<template>
  <div>
    <header>
      <div
        id="header-sticky"
        class="header-area header-transparent header-pad header-border sticky"
      >
        <div class="container-fluid">
          <div class="row align-items-center">
            <div class="col-xl-2 col-lg-2 col-6 text-start">
              <div class="logo logo-border text-sx">
                <router-link class="active" to="/">
                  <img
                    class="logo-white"
                    src="../assets/img/preloader/logo w-b.svg"
                    alt="logo"
                  />
                </router-link>
              </div>
            </div>
            <div class="col-xl-8 col-lg-8 d-none d-lg-block">
              <div class="main-menu text-center">
                <nav id="mobile-menu">
                  <ul>
                    <li>
                      <router-link class="active" to="/">Home</router-link>
                    </li>
                    <li>
                      <router-link to="/about">About</router-link>
                    </li>

                    <li>
                      <router-link to="/coworking">coworking</router-link>
                      <ul class="sub-menu">
                        <li><router-link to="/spazi">Spazi</router-link></li>
                        <li>
                          <router-link to="/innovazioni"
                            >Innovazioni</router-link
                          >
                        </li>
                      </ul>
                    </li>
                    <li>
                      <router-link to="/well_be">My Well-B</router-link>
                    </li>
                    <li>
                      <a href="https://app.well-b.space/login">{{ $t('menu.book') }}</a>
                    </li>
                    <li>
                      <router-link to="/contatti">{{
                        $t("menu.contacts")
                      }}</router-link>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
            <div
              style="padding: 0px"
              class="col-xl-2 col-lg-2 col-6 d-none text-center"
            >
              <div
                style="margin-left: 0px"
                class="header-btn d-flex align-items-center justify-content-around"
              >
                <a
                  style="color: white; font-weight: 600; padding: 0 40px"
                  href="/contatti"
                  class="tp-btn"
                  >Accedi</a
                >
                <!--  <div class="language">
                  <a
                    class="nav-link"
                    href="#partners"
                    
                    @click="
                      v-smooth-scroll
                      handleChangeIt();
                    "
                    >IT</a
                  >
                  <span>/</span>
                  <a
                    class="nav-link"
                    href="#partners"
                    
                    @click="
                      
                      handleChangeEn();
                    "
                    >EN</a
                  >
                </div> -->
              </div>
            </div>
            <div class="d-lg-none col-6">
              <div
                class="header-right-info d-flex align-items-center justify-content-end"
              >
                <div @click="menu()" class="sidebar__menu d-lg-none">
                  <div class="sidebar-toggle-btn ml-30" id="sidebar-toggle">
                    <span class="line"></span>
                    <span class="line"></span>
                    <span class="line"></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
    <div id="hamb" class="sidebar__area">
      <div class="sidebar__wrapper">
        <div class="sidebar__close">
          <button
            @click="menu()"
            class="sidebar__close-btn"
            id="sidebar__close-btn"
          >
            <i class="fal fa-times"></i>
          </button>
        </div>
        <div class="sidebar__content">
          <div class="logo mb-40">
            <a href="/">
              <img src="../assets/img/preloader/logo w-b.svg" alt="logo" />
            </a>
          </div>
          <div class="mobile-menu fix mean-container">
            <nav class="mean-nav">
              <ul>
                <li>
                  <a class="active" href="/">Home</a>
                </li>
                <li>
                  <a href="/about">About</a>
                </li>
                <li>
                  <a href="/coworking">coworking</a>
                  <ul id="st-menu" class="sub-menu d-none">
                    <li><a href="/spazi">Spazi</a></li>
                    <li><a href="/innovazioni">Le Innovazioni</a></li>
                  </ul>
                  <a
                    @click="sotMen()"
                    class="mean-expand"
                    href="#"
                    style="font-size: 18px"
                    ><i class="fal fa-plus"></i
                  ></a>
                </li>
                <li>
                  <a href="/well_be">My Well-B</a>
                </li>
                <li>
                  <a href="https://app.well-b.space/login">{{ $t('menu.book') }}</a>
                </li>
                <li class="mean-last">
                  <a href="/contatti">Contatti</a>
                </li>
              </ul>
            </nav>
          </div>

          <div
            class="sidebar__search p-relative mt-40 mb-20 text-center d-none"
          >
            <li>
              <a
                style="color: #222429; font-size: 18px"
                class="active text-uppercase"
                href="/"
                >Accedi</a
              >
            </li>
          </div>
          <div class="sidebar__contact mb-45 mt-70">
            <ul>
              <li class="wow fadeInUp" data-wow-delay="1s">
                <div class="icon theme-color">
                  <i class="fal fa-envelope"></i>
                </div>
                <div class="text theme-color">
                  <span
                    ><a href="mailto:scrivici@well-b.space"
                      >scrivici@well-b.space</a
                    ></span
                  >
                </div>
              </li>
              <!-- <li class="wow fadeInUp" data-wow-delay="1s">
                <div class="icon theme-color">
                  <i class="fas fa-phone-volume"></i>
                </div>
                <div class="text theme-color">
                  <span
                    ><a href="tel:(+642)-394-396-432"
                      >(+642) 394 396 432</a
                    ></span
                  >
                </div>
              </li> -->
              <li class="wow fadeInUp" data-wow-delay="1s">
                <div class="icon">
                  <i class="fal fa-map-marker-alt"></i>
                </div>
                <div class="text text-start">
                  <a
                    target="_blank"
                    href="https://goo.gl/maps/TuNs5aA8xxaHuPAZ7"
                    >Via Finocchiaro Aprile 5, <br> 20124 Milano</a
                  >
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <!-- sidebar area end -->

    <div class="body-overlay transition-3"></div>
  </div>
</template>

<script>
export default {
  name: "Header",
  data() {
    const lang = localStorage.getItem("lang");
    return {
      langBool: false,
      lang: lang,
    };
  },
  methods: {
    menu() {
      document.getElementById("hamb").classList.toggle("sidebar-opened");
    },
    sotMen() {
      document.getElementById("st-menu").classList.toggle("d-none");
    },
    handleChangeEn() {
      localStorage.setItem("lang", "en");
      window.location.reload();
    },
    handleChangeIt() {
      localStorage.setItem("lang", "it");
      window.location.reload();
    },
  },
};
</script>

<style></style>
