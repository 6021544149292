<template>
  <div class="home">
    <div v-if="isLoading">
      <Loader :isLoading="isLoading" />
    </div>
   <div v-if="!isLoading">
    <Header />
    <main>
      <!-- slider area start -->
      <div class="slider-area fix">
        <div class="swiper-wrapper">
          <div class="slider-list swiper-slide d-flex align-items-center pt-70">
            <div class="slider-bk"></div>
            <div class="container">
              <div class="row">
                <div class="col-12 position-relative">
                  <!-- <div class="slider-circle-shape"></div> -->
                  <div class="slider-text z-index text-center">
                    <span
                      class="slider-sub-title"
                      data-animation="fadeInUp"
                      data-delay="0.3s"
                      ><strong>{{$t("home.presentation")}}</strong></span
                    >
                    <p
                      v-html="$t('home.paragraph')"
                      class="slider-title text-small-center"
                      data-animation="fadeInUp"
                      data-delay="0.5s"
                    >
                    </p>
                    <div
                      class="slide-btn mt-30"
                      data-animation="fadeInUp"
                      data-delay="0.7s"
                    >
                      <router-link style="color: white" class="tp-btn" to="/about">{{$t('home.button')}}</router-link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- If we need pagination -->
          <!-- <div class="swiper-pagination"></div> -->

          <!-- If we need navigation buttons -->
        </div>
      </div>
      <!-- slider area end -->

      <!-- services area start -->
      <div class="black-bg">
        <div class="main-services pt1 pt-120 pb-90">
          <div class="container">
            <div class="row mb-60">
              <div class="col-12">
                <div class="sec-wrapper text-center">
                  <h2 class="section-title text-white">
                    {{$t("home.box1Title")}}
                  </h2>
                  <p v-html="$t('home.box1Paragraph')" class="text-white">
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- services area end -->

      <!-- about start -->
      <div class="about-area pt-120 pb-90">
        <div class="container">
          <div class="row">
            <div class="col-xl-6 col-lg-6">
              <div class="ab-wrapper pos-rel">
                <div class="ab-since"></div>
                <div class="row gx-2 mb-30">
                  <div class="col-6">
                    <div class="ab-img img-filter">
                      <img src="../assets/img/about/HomePage_Quadro1.webp" alt="" />
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="ab-img img-filter">
                      <img src="../assets/img/about/HP Griglia 4.webp" alt="" />
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="ab-img img-filter">
                      <img src="../assets/img/about/HomePage_Quadro3.webp" alt="" />
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="ab-img img-filter">
                      <img src="../assets/img/about/Home.webp" alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xl-6 col-lg-6">
              <div class="ab-box pl-50 mb-30">
                <div class="sec-wrapper">
                  <h2 class="section-title text-sx">
                    {{$t("home.box2Title")}}
                  </h2>
                </div>
                <div class="ab-item-box mt-40 mb-30 ">
                  <p v-html="$t('home.box2Paragraph')">
                  </p>
                  
                </div>
                <div class="ab-btn text-sx">
                  <router-link style="color: white" class="tp-btn" to="/about">{{$t('home.button')}}</router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- about end -->

    
      <!-- team  -->

      <!-- faq  -->
      <div
        class="black-bg"
      >
      <div class="dot faq-area black-bg pt-120 pb-90">
        <div class="container">
          <div class="row">
            <div class="col-xl-7 col-lg-7">
              <div class="sec-wrapper mb-30">
                <h2 class="section-title mb-50 text-sx">{{$t("home.box3Title")}}</h2>
                <p v-html="$t('home.box3Paragraph')" class="text-white">
                 
                </p>
                

                <div class="ab-btn mt-50 text-sx">
                  <router-link style="color: white" class="tp-btn" to="/about">{{$t('home.button')}}</router-link>
                </div>
              </div>
            </div>
            <div class="col-xl-5 col-lg-5">
              <div class="box-bk">
                <img src="../assets/img/pattern/sicurezza.webp" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
    </main>
    <Footer />
  </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Loader from "@/components/Loader.vue";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: "Home",
 
  data(){
     return{
        isLoading: true 
     }
  },
  components: {
    Header,
    Footer,
    Loader
  },
    mounted() {
    setTimeout(() => {
      this.isLoading = false;
    }, 1000);
  },

};
</script>
