<template>
  <div class="footer">
    <!-- footer area start -->
    <footer class="bk-footer" data-background="../assets/img/bg/04.png">
      <div class="bk-img-footer">
        <div class="footer-top pt-115 pb-90">
          <div class="container">
            <div class="row">
              <div class="col-md-10 offset-md-1 col-lg-3 offset-lg-1 mb-5">
                <div class="footer-widget mb-60">
                  <div class="footer-logo mb-30 text-sx">
                    <a href="#"
                      ><img src="../assets/img/preloader/logo w-b.svg" alt=""
                    /></a>
                  </div>
                  <div class="footer-text mb-20">
                    <p style="font-size: 14px">
                      Well-B Space è un progetto promosso dalla start-up
                      innovativa Well-Smart Invest Srl, iscritta al Registro
                      delle imprese di Milano secondo i requisiti dell’art. 25
                      del D.L. 18 ottobre 2012, n.179.
                    </p>
                  </div>
                  <h3 style="height:5px" class="footer-title text-sx">P.IVA</h3>
                  <div class="footer-menu-2">
                    <p>
                      WELL SMART INVEST S.R.L. Via Cadea 25 22045 Lambrugo (CO) <br>
                      P.IVA 03929320137
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-md-10 offset-md-1 col-lg-3 offset-lg-1 mb-5">
                <div class="footer-widget mb-60">
                  <h3 class="footer-title text-sx">Informazioni</h3>
                  <div class="footer-menu-2">
                    <p>
                      Iscritta nella sezione speciale della Camera di Commercio
                      di Como-Lecco <br />
                      Sede Legale: Via Cadea 29, Lambrugo (CO) 22045 <br />
                      Sede Operativa e degli Spazi: Via Camillo Finocchiaro
                      Aprile 5, Milano (MI) 20124
                    </p>
                  </div>
                  <a class="d-flex flex-column justify-content-start align-items-start" href="">
                        <h3 style="margin-bottom:40px" class="footer-title text-sx">Art Partner</h3>
                        <img width="70px" class="" src="../assets/logo_GILDA.png" alt="">
                      </a>
                </div>
              </div>

              <div class="col-md-10 offset-md-1 col-lg-3 offset-lg-1 mb-5">
                <div class="footer-widget mb-50 text-sx">
                  <h3 class="footer-title">Social</h3>
                  <div class="">
                    <ul class="d-flex flex-column social-footer">
                      <li style="margin-bottom: 15px; color: white">
                        <a
                          href="https://www.facebook.com/wellbspace"
                          target="_blank"
                          >FACEBOOK</a
                        ><i
                          class="fab fa-facebook-f"
                          style="margin-left: 20px; padding-top: 2px"
                        ></i>
                      </li>
                      <!-- <li  style="margin-bottom:15px;  color: white;"> <a  href="#">INSTAGRAM</a><i class="fab fa-instagram" style="margin-left: 12px; padding-top: 4px;"></i> </li> -->
                      <li style="margin-bottom: 15px; color: white">
                        <a
                          href="https://www.linkedin.com/company/wellbspace"
                          target="_blank"
                          >LINKEDIN</a
                        ><i
                          class="fab fa-linkedin"
                          style="margin-left: 32px; padding-top: 3px"
                        ></i>
                      </li>
                      <li style="margin-bottom: 15px; color: white">
                        <a
                          href="https://www.instagram.com/wellb_space/"
                          target="_blank"
                          >INSTAGRAM</a
                        ><i
                          class="fab fa-instagram"
                          style="margin-left: 15px; padding-top: 3px"
                        ></i>
                      </li>
                    </ul>
                
                  
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="copyright-area pt-25 pb-20">
          <div class="container">
            <div class="row">
              <div class="col-12">
                <p class="white-color text-center">
                  Developed with <i class="fa fa-heart pulse"></i>
                  <a href="https://yomi.digital/" target="_blank"> by YOMI</a>
                </p>
              </div>
              <!-- <div class="col-md-6">
                        <div class="footer-social text-start text-md-end">
                           <a href="#"><i class="fab fa-facebook-f"></i></a>
                           <a href="#"><i class="fab fa-youtube"></i></a>
                           <a href="#"><i class="fab fa-linkedin"></i></a>
                           <a href="#"><i class="fab fa-twitter"></i></a>
                           <a href="#"><i class="fab fa-instagram"></i></a>
                        </div>
                     </div> -->
            </div>
          </div>
        </div>
      </div>
    </footer>
    <!-- footer area end -->

    <div @click="scrollToTop()" class="progress-wrap active-progress">
      <svg
        class="progress-circle svg-content"
        width="100%"
        height="100%"
        viewBox="-1 -1 102 102"
      >
        <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98" />
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    scrollToTop() {
      window.scrollTo(0, 0);
    },
  },
};
</script>

<style></style>
