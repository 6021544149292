<template>
  <div class="about">
    <Header />
      <main>
         <!-- page title area start -->
         <section class="page__title p-relative d-flex align-items-center bk-page" >
            <div class="page__title-bg bk-spazi"></div>
            <div class="container">
                  <div class="row">
                     <div class="col-xl-12">
                        <div class="page__title-content mt-100 text-center">
                              <h2>Le Innovazioni</h2>
                        </div>
                     </div>
                  </div>
            </div>
         </section>
         <!-- page title area end -->



         
      <!-- testimoinial area start -->
      <section class="review-area review-area-padding  pb-120 pos-rel pl-50 pr-50">
         <div class="container">
            <div class="row mb-60 pt-150 z-index">
               <div class="col-xl-6 col-lg-6 mb-40">
                  <p v-html="$t('innovazioni.description')">
                  </p>                 
               </div>
               <div class="col-xl-6 col-lg-6">
                  <div class="ab-wrapper pos-rel">
                     <div class="ab-since">
                     </div>
                     <div class="row gx-2 mb-30">
                        <div class="col-6">
                           <div class="ab-img img-filter">
                              <img src="../assets/img/about/Innovazioni Griglia 1.webp" alt="">
                           </div>
                        </div>
                        <div class="col-6">
                           <div class="ab-img img-filter">
                              <img src="../assets/img/about/Innovazioni Griglia 2.webp" alt="">
                           </div>
                        </div>
                        <div class="col-6">
                           <div class="ab-img img-filter">
                              <img src="../assets/img/about/Innovazioni Griglia 3.webp" alt="">
                           </div>
                        </div>
                        <div class="col-6">
                           <div class="ab-img img-filter">
                              <img src="../assets/img/about/Innovazioni Griglia 4.webp" alt="">
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <div class="row z-index d-flex justify-content-between m-auto">
               <div  class="col-xl-6 col-lg-6 col-md-6 col-sm-12 box-testo">
                  <h3>{{$t('innovazioni.box1title')}}</h3>
                  <p v-html="$t('innovazioni.box1paragraph')">
                  </p>
               </div>
               <div  class="col-xl-6 col-lg-6 col-md-6 col-sm-12  box-testo">
                  <h3>{{$t('innovazioni.box2title')}}</h3>
                  <p v-html="$t('innovazioni.box2paragraph')"></p>
                  <ul class="ps-4 mt-30 mb-30">
                     <li v-html="$t('innovazioni.box2li1')" class="mb-20"><span ></span></li>
                     <li v-html="$t('innovazioni.box2li2')" class="mb-20"></li>
                     <li v-html="$t('innovazioni.box2li3')" class="mb-20"></li>
                     <li v-html="$t('innovazioni.box2li3')"></li>
                  </ul>
                  <p v-html="$t('innovazioni.other')">   
                  </p>
               </div>              
            </div>
         </div>
      </section>
      <!-- testimoinial area end -->

      
      </main>
    <Footer />
  </div>
</template>

<script>
// @ is an alias to /src
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: "Innovazioni",
  components: {
    Header,
    Footer,
  },
};
</script>
