import Vue from 'vue'
import App from './App.vue'
import router from './router'
import i18n from "./i18n";

import ErrorPage from 'vue-error-page';
 
window.eventBus = new Vue();
 
Vue.use(ErrorPage);

const lang = localStorage.getItem("lang") || "en";
document.documentElement.lang = lang;

import "./assets/css/animate.min.css";
import "./assets/css/backToTop.css";
import "./assets/css/bootstrap.min.css";
import "./assets/css/default.css";
import "./assets/css/elegenticons.css";
import "./assets/css/fontAwesome5Pro.css";
import "./assets/css/jquery.fancybox.min.css";
import "./assets/css/meanmenu.css";
import "./assets/css/preloader.css";
import "./assets/css/swiper-bundle.css";
import "./assets/css/style.css";




Vue.config.productionTip = false

new Vue({
  router,
  i18n,
  render: h => h(App)
}).$mount('#app')
