<template>
  <div class="contatti">
    <Header />
    <main>
      <!-- page title area start -->
      <section
        class="page__title p-relative d-flex align-items-center bk-page-2"
        data-overlay="dark"
        data-opacity="3"
      >
        <div class="page__title-bg bk-contact"></div>
        <div class="container">
          <div class="row">
            <div class="col-xl-12">
              <div class="page__title-content mt-100 text-center">
                <h2>contatti</h2>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- page title area end -->
      <div
        style="background-color: #1d4c44"
        class="faq-area black-bg dot pt-120 pb-90"
      >
        <div class="container">
          <div class="row">
            <div class="col-12">
              <div class="sec-wrapper mb-30 text-center">
                <img
                  class="mb-4"
                  width="250px"
                  src="../assets/img/preloader/logo w-b.svg"
                  alt=""
                />
                <p style="font-size: 22px" class="text-white text-center">
                  Well-B Space è un progetto promosso dalla start-up innovativa
                  Well-Smart Invest Srl, iscritta al Registro delle imprese di
                  Milano secondo i requisiti dell’art. 25 del D.L. 18 ottobre
                  2012, n.179.
                </p>

                <div class="ab-btn mt-50">
                  <a style="color: white" href="/about" class="tp-btn">{{
                    $t("home.button")
                  }}</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- contact area  -->
      <section class="contact-area pt-120 pb-80 fix">
        <div class="container">
          <div class="row">
            <div class="col-12 col-md-10 offset-0 offset-md-1">
              <div class="section-title-wrapper mb-15 text-center">
                <h5 class="section-subtitle mb-20 ">contattaci</h5>
                <h2 class="section-title ">Parla con un <br /> nostro operatore</h2>
              </div>
              <div class="contact-info mr-50 mr-xs-0 mr-md-0 text-sx">
                <!-- <div class="single-contact-info d-flex align-items-center">
                  <div class="contact-info-icon">
                    <a href="#"><i class="fad fa-comments"></i></a>
                  </div>
                  <div class="contact-info-text mt-10">
                    <span>telefono</span>
                    <h5><a href="tell:926668880000">92 666 888 0000</a></h5>
                  </div>
                </div> -->
                <div class="single-contact-info d-flex align-items-center">
                  <div class="contact-info-icon">
                    <a  href="mailto:scrivici@well-b.space"><i class="fal fa-envelope"></i></a>
                  </div>
                  <div class="contact-info-text mt-10">
                    <span> email</span>
                    <h5>
                      <a href="mailto:scrivici@well-b.space"
                        >scrivici@well-b.space</a
                      >
                    </h5>
                  </div>
                </div>
                <div class="single-contact-info d-flex align-items-center">
                  <div class="contact-info-icon">
                    <a href="https://goo.gl/maps/TuNs5aA8xxaHuPAZ7"><i class="fal fa-map-marker-alt"></i></a>
                  </div>
                  <div class="contact-info-text mt-10 mb-2">
                    <span>indirizzo</span>
                    <h5>
                      <a
                        target="_blank"
                        href="https://goo.gl/maps/TuNs5aA8xxaHuPAZ7"
                        >Via Finocchiaro Aprile 5, 20124 Milano</a
                      >
                    </h5>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xxl-7 col-xl-6 col-lg-6 d-none">
              <div class="contact-form">
                <div class="row">
                  <div class="col-xxl-6 col-xl-6 col-lg-6 mb-20">
                    <input
                      v-model="name"
                      name="name"
                      type="text"
                      placeholder="Your Name*"
                    />
                  </div>
                  <div class="col-xxl-6 col-xl-6 col-lg-6 mb-20">
                    <input
                      name="email"
                      v-model="email"
                      type="email"
                      placeholder="Email Adress*"
                    />
                  </div>
                  <div class="col-xxl-6 col-xl-6 col-lg-6 mb-20">
                    <input
                      v-model="phone"
                      name="phone"
                      type="text"
                      placeholder="Phone*"
                    />
                  </div>
                  <div class="col-xxl-6 col-xl-6 col-lg-6 mb-20">
                    <input
                      v-model="subject"
                      name="subject"
                      type="text"
                      placeholder="Subject"
                    />
                  </div>
                  <div class="col-xxl-12 col-xl-12 col-lg-12 mb-20">
                    <textarea
                      placeholder="Write Massage"
                      v-model="message"
                      name="message"
                    ></textarea>
                  </div>
                  <div class="d-flex align-items-baseline">
                    <input
                      style="width: fit-content"
                      class="p-0 me-2"
                      type="checkbox"
                      id="checkInfo"
                      @click="checked = !checked"
                    />
                    <p style="font-size:0.9rem">Accetta <a class="text-decoration-underline" href="/file.pdf" target="_blank">l'informativa</a></p>
                  </div>
                  <!--  <vue-recaptcha ref="recaptcha" sitekey="6Lf0O1oeAAAAAPGno5yQboeCAWmz1vm9XHoWLuRS"></vue-recaptcha> -->
                  <div
                    ref="grecaptcha"
                    class="g-recaptcha"
                    data-sitekey="6Lf0O1oeAAAAAPGno5yQboeCAWmz1vm9XHoWLuRS"
                  ></div>
                  <div class="col-xxl-12 col-xl-12 mb-20 text-sx">
                    <button
                      v-if="!sending"
                      @click="sendMail()"
                      type="submit"
                      :disabled="!formIsValid || !checked"
                      class="tp-btn"
                    >
                      Invia Messaggio
                    </button>
                    <div v-if="sending" style="color: #5d5d5d">
                      Invio messaggio, attendere...
                    </div>
                  </div>
                </div>
                

                <p class="ajax-response"></p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- contact area end -->
      <!-- contact map area  -->
      <div class="contact-map">
        <div id="contact-map">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2797.582573542415!2d9.198651715557878!3d45.47821207910115!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4786c6c7cc3a1ba9%3A0xd2a2c1c7983fbef1!2sVia%20Camillo%20Finocchiaro%20Aprile%2C%205%2C%2020124%20Milano%20MI!5e0!3m2!1sit!2sit!4v1643882124447!5m2!1sit!2sit"
            width="600"
            height="450"
            style="border: 0"
            allowfullscreen=""
            loading="lazy"
          ></iframe>
        </div>
      </div>
      <!-- contact map area end  -->
    </main>
    <Footer />
  </div>
</template>

<script>
// @ is an alias to /src
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import axios from "axios";

export default {
  name: "Contatti",
  data() {
    return {
      checked: false,
      sending: false,
      name: "",
      email: "",
      phone: "",
      subject: "",
      message: "",
      recaptchaResponse: "",
      token: "",
    };
  },
  components: {
    Header,
    Footer,
  },
  computed: {
    formIsValid() {
      // validate email name ecc...
      if (!this.recaptchaResponse) {
        return false;
      }
      return true;
    },
  },
  mounted() {
      window.grecaptcha.render(this.$refs.grecaptcha, {
        sitekey: "6Lf0O1oeAAAAAPGno5yQboeCAWmz1vm9XHoWLuRS",
        callback: (response) => (this.recaptchaResponse = response),
        "expired-callback": () => {
          this.captcha.response = null;
        },
        // size: 'invisible'
      });
      console.log('=====>',this.recaptchaResponse)
  },
  methods: {
    async sendMail() {
      const app = this;
      if (!app.sending) {
        if (
          app.name == undefined ||
          app.name == "" ||
          app.email == undefined ||
          app.email == "" ||
          app.phone == undefined ||
          app.phone == ""
        ) {
          alert("Inserire i campi mancanti");
        } else {
          app.sending = true;
          try {
            let response = await axios.post(
              "https://app.well-b.space/contacts-mail", 
            {
              name: app.name,
              email: app.email,
              phone: app.phone,
              subject: app.subject,
              message: app.message,
              "g-recaptcha-response": this.recaptchaResponse,
            }
            );
            alert(response.data.message);
          } catch (e) {
            console.log(e);
          }
            app.sending = false;
            app.name = "";
            app.email = "";
            app.phone = "";
            app.subject = "";
            app.message = "";
            app.recaptchaResponse = "";
        }
      }
    },
  },
};
</script>
